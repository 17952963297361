 .sub-tabs-form .sub-tabs{
  border-bottom: 1px solid;
  border-bottom-color: gainsboro !important;
  margin-top: -35px;
}

.even-group-color {
  width: 40px;
  height: 30px;
  border-radius:2px;
  border: 1px solid gainsboro;
  background-color: white;
  border-color: 1px gainsboro;
}

.even-group-inner-color {
  width: 30px;
  height: 20px;
  border-radius:2px;
  margin-top: 4px !important;
  margin: auto;
  /* background-color: yellow; */
}
.even-group-delete {
  color: red;
}
.table-group-row {
  margin-top: -25px;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
  color: rgb(141, 141, 141);
}