.admin-content {
  margin-top: 30px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-group-addon {
  padding: 0.6rem 1.4rem 0px 1rem;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

.input-group-addon:hover,
.input-group-addon:active {
  background-color: #0056B2;
  color: #eee
}


.suffix {
  border-radius: 4px 0px 0px 4px;
}

.suffix .input-group-addon {
  border-left: 0;
  border-radius: 0px 4px 4px 0px;
}

.prefix {
  border-radius: 0px 4px 4px 0px;
}

.prefix .input-group-addon {
  border-right: 0;
  border-radius: 4px 0px 0px 4px;
}

.presuffix {
  border-radius: 0px;
}

.input-group-addon.prefix {
  border-radius: 4px 0px 0px 4px;
  border-right: 0;
}

.input-group-addon.suffix {
  border-radius: 0px 4px 4px 0px;
  border-left: 0;
}

.input-group {
  position: relative !important;
  display: flex;
  border-collapse: separate;
}


.LOW {
  padding: 0.1rem 0.5rem;
  color: #fff;
  border-radius: 5px;
  font-size: 0.8rem;
  background-color: blue;
}

.HIGH {
  padding: 0.1rem 0.5rem;
  color: #fff;
  border-radius: 5px;
  font-size: 0.8rem;
  background-color: red;
}

.MEDIUM {
  padding: 0.1rem 0.5rem;
  border-radius: 5px;
  font-size: 0.8rem;
  background-color: yellow;
}

.error-box {
  color: #fff;
  overflow-y: auto;
  background-color: #2B303B;
  height: 200px;
  white-space: pre-wrap;
}

.admin-page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
  }

  h2 {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
}

.card {
  margin-bottom: 20px;

  .card-img-top {
    object-fit: cover;
    background: #ddd;
    border: 0;
  }

  // button space right
  .space-right {
    margin-right: 10px !important;
  }
}

.translucent-overlay,
.leagues-team {
  position: relative;
}

.kicked-overlay {
  position: absolute;
  z-index: 99999;
  width: 100%;
  height: 100%;
  margin: 10px 0;
  text-align: center;
  font-size: 0.9rem;
}

.translucent-overlay {
  filter: blur(1.5px);
  transition: 0.2s;
}

.translucent-overlay:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}

.image-dropzone {
  margin: 20px;
  border: 3px dashed rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 30px 25px;
  text-align: center;
  color: #999;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.1rem;
  font-size: 0.8rem;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.025);
  }
}

.editing-image {
  margin: -16px;
  width: calc(100% + 32px);
}

.avatar-editor-backdrop {
  background: black;
  width: 100%;
  padding: 20px;

  canvas {
    display: block;
    margin: 0 auto;
    background-image: url("./img/trans-bg.png");
    background-size: 200px auto;
  }
}

.avatar-editor-controls {
  padding: 15px;
}

.edit-image-btn {

  background: rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-position: 50% 50%;
  width: 200px;
  height: 200px;
  border-radius: 7px;
  color: #ddd;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.mediaImages {
    // width: 100%;
    // height: 100%;
  }
}

.reorder-list {
  .card {
    cursor: grab;
    z-index: 1;

    &:active {
      cursor: grabbing;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
      z-index: 2;
    }
  }
}

.compact-card {
  margin-bottom: 10px;
}

.compact-card .card-body {
  padding-top: 5px;
  padding-bottom: 5px;
}

.popover {
  position: 'absolute';
  z-index: '2',
}

.cover {
  position: 'fixed';
  top: '0px';
  right: '0px';
  bottom: '0px';
  left: '0px';
}

.sub-nav {
  border-bottom: 1px solid #dee2e6;
}

.important-dates-content {
  padding-top: 20px
}

.list-group-item.roster-team {
  padding: 5px 10px;

}

.list-group-item.roster-team.active {
  border-radius: 6px;
}

.roster-card {
  min-height: 300px;
}

.relative {
  position: relative;
}

.abs-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  background: rgba(255, 255, 255, .78);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardHeader {
  font-weight: 300;
  font-size: 1.2em;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.member-card {
  min-height: auto;
}

.playerInfo {
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 1em;
  padding-left: 1em;
}

.team-member-img {
  width: 35px;
  height: 35px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 100%;
  background-color: #999;
  margin-bottom: 1em;
}

.no_profile {
  flex-shrink: 0;
  width: 130px;
  height: 130px;
  font-size: 13px;
  background: rgba(62, 171, 240, 0.1);
  color: var(--primary);
  text-align: center;
  line-height: 35px;
  font-weight: 500;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 100%;
  margin-bottom: 1em;
}

.playerTitle {
  font-weight: bold;
}

.playerSubtitle {
  font-weight: normal;
  font-size: 0.8em;
  font-style: italic;
}

.playerNationality {
  margin-left: 1em;
  text-transform: uppercase;
}

.playerActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.addMember {
  width: -webkit-fill-available;
  margin-bottom: 2em;
}

.playerPosition {
  margin-top: 0.6em;
}

.inviteMember {
  width: -webkit-fill-available;
}

.flex-sb {
  display: inline-flex;
  align-items: center;
}

.title-subtitle {
  p {
    font-size: 0.95rem;
  }

  small {
    font-size: 0.75rem;
    margin: 0;
  }
}

.title,
.subtitle {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  margin: 0 0 -2px 0;
  font-size: 0.95em;
}

.subtitle {
  margin: 0px 0 0 10px;
  font-size: 0.8rem;
  color: #999;
}

.type {

  background: #dfe2e5;
  margin-top: 0.3em;
  color: #212528;
  font-size: 0.55rem;
  font-weight: bold;
  padding: 6px 7px 4px 7px;
  border-radius: 100px;
  text-transform: uppercase;
  float: right;
}

.search-result-img {
  width: 30px;
  height: 30px;
  background-color: #444;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 100%;
  flex-shrink: 0;
  margin-right: 0.8em;
}

.profile-img-with-inital-1-letter {
  width: 30px;
  height: 30px;
  background: #dfe2e5;
  border-radius: 100%;
  padding: 2px 0 0 9px;
  margin-right: 0.8em;
}


.dynamic-search-selected-items {
  margin: 15px 0;
  height: auto;

  .selected-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .item-details {
      display: flex;
      align-items: center;
      width: calc(100% - 30px);

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .item-img {
      flex-shrink: 0;
      width: 33px;
      height: 33px;
      background-color: #444;
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 100%;
      margin-right: 12px;
    }

    .item-user-name {
      font-size: 0.9rem;
      color: #999;
      margin-left: 10px;
    }

    .item-name-multi {
      p {
        font-size: 0.9rem;
        font-weight: bold;
        margin: 5px 0 1px 0;
        color: #212529;
      }

      small {
        margin: 0;
        color: #68717e;
      }
    }

    select.form-input {
      width: 145px;
      margin-right: 10px;
      height: 2em;
      background-color: #dfe2e5;
    }

    button {
      appearance: none;
      background: none;
      border: 0;
      outline: 0;
      color: #777;
      width: auto;
      padding: 5px;
      margin: 0;
      cursor: pointer;
    }
  }
}

.roleModalButton {
  width: -webkit-fill-available;
  margin-top: 0.5em;
}

.roleSwitches {
  margin-bottom: 0.7em;
  .custom-control-label{
    color: #000 !important;
  }
}

.roleSwitchesParent {
  margin-bottom: 0.7em;
  // margin-left: 3em;
  .custom-control-label{
    color: #000 !important;
  }
}

.roleSwitchesChild {
  margin-bottom: 0.7em;
  // margin-left: 6em;
  .custom-control-label{
    color: #000 !important;
  }
}

.roleName {
  background-color: rgb(174, 174, 174) !important;
}

.ownPermission {
  padding: 1em;
  border: 1px solid #d5d7d9;
  border-radius: 1%;
  margin-bottom: 1em;
}

.inheritedPermission {
  padding: 1em;
  border: 1px solid #d5d7d9;
  background-color: #f5f6f7;
  border-radius: 1%;
  margin-bottom: 1em;
  min-height: 7.4em;
}

.orgNames {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.3em;
  padding: 5px 0px 5px 40px;
}

.orgName {
  margin-top: 0.3em;
  font-size: smaller;
}

.disabled-link {
  pointer-events: none;
  opacity: .65;
}

.hiddenText {
  float: right;
  color: red;
  background-color: white;
  border: 1px solid;
  font-size: 0.6em;
  padding: 0 0.3em 0 0.3em;
  margin-bottom: 10px;
}
.displayOptions {
  display: -webkit-inline-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: -webkit-fill-available;
  width: 100%;
  position: relative;
  text-align: center;
}
.showDate {
  color: red;
  font-size: 0.6em;
  background-color: white;
  border: 1px solid;
  padding: 0.2em;
  width: 10em;
  overflow: hidden; /* make sure it hides the content that overflows */
  white-space: nowrap; /* don't break the line */
  text-overflow: ellipsis;
  position: absolute;
  right: 0%;  
  top: 13%;
}

@media screen and (min-width: 1800px) {
  .showDate {
    width: 15em; /* the element needs a fixed width (in px, em, %, etc) */
  }
}
@media (min-width:1199px) and (max-width:1430px) {
  .showDate {
    width: 3em; /* the element needs a fixed width (in px, em, %, etc) */
  }
}
@media (min-width:1000px) and (max-width:1198px) {
  .showDate {
    width: 13em; /* the element needs a fixed width (in px, em, %, etc) */
  }
}