.sub-tabs-form-seeding .sub-tabs {
  border-bottom: 1px solid;
  border-bottom-color: gainsboro !important;
  margin-top: 0em;
  border-radius: 5px;
}

.w250 {
  width: 250px !important;
}
.disabled {
  opacity: 0.6;
  pointer-events: none !important;
  cursor: no-drop;
  background-color: rgba(225, 225, 225, 0.7) !important;
}
.sub-tabs-from-seeding {
  margin: -3px 0px 0px 0px !important;
}
.even-group-inner-color {
  width: 30px;
  height: 20px;
  border-radius: 2px;
  margin: auto;
}
.even-group-delete {
  color: red;
}
.table-group-row {
  margin-top: 0px;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
  color: rgb(141, 141, 141);
}
