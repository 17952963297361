.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 85%;
  /* width: fit-content; */
  margin-top: 5px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
}

.option-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}
/* .option-item:hover {
  color: #fff;
  background-color: #2c8bf1;
  cursor: pointer;
} */

.disabled-option-wrapper {
  opacity: 0.6;
  pointer-events: none;
  background: rgba(225, 225, 225, 0.7) !important;
  border-radius: 0.025rem;
}

.hovered {
  color: #fff;
  background-color: #2c8bf1;
  cursor: pointer;
}

.down-arrow-opt {
  position: absolute;
  height: 10px;
  top: 12px;
  right: 5px;
}
