/* .custom-control-input:disabled~.custom-control-label, .custom-control-input[disabled]~.custom-control-label .roleSwitches .label{
  color: #000;
} */
.roleSwitches .custom-control-label{
  color: #000 !important;
}
.roleSwitchesParent .custom-control-label{
  color: #000 !important;
}
.roleSwitchesChild .custom-control-label {
  color: #000 !important;
}
.super-user .form-control {
  color: #000 !important;
}